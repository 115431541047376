@import '../../variables';

// .error-box-container {}
.error-box-inner {
  border: 1px solid darken($tertiary, 60%);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: $tertiary;

  p {
    margin: 0;
  }
}
.error-box-retry{
  display: flex;
  width: 100%;
  border: 1px solid darken($tertiary, 60%);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: $tertiary;
  justify-content: space-between;
}

.error-box-inner-retry-section-btn{
  background-color: gray !important;
  color:white !important;
}

.error-box-inner-retry-section-btn:hover{
  background-color: gray !important;
  color:white !important;
}
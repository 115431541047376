@import '../../../variables.scss';

.chat-history-window {
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  // border: $border-thickness solid $primary;
  // border-right: $border-thickness solid $primary;
  // margin-right: -$border-thickness;
  height: 100%;
  overflow-y: auto;
  background-color: $background-gray-ultralight;

  .chat-sessions {
    padding-top: 15px;
    flex: 1;
    overflow-y: auto;

    .chat-session-container {
      padding: 10px;
      margin: 5px 0;
      border-radius: $standard-border-radius;
  
      &.active, &:hover {
        border: 1px solid $primary;
      }

      // &.active {
      // }

      &:hover {
        cursor: pointer;

        &.active {
          cursor: default;
        }
      }
  
      .chat-session-name {
        margin: 0;
        font-weight: bold;
        color: $dark-gray;
      }

      .chat-session-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .delete-button {
          opacity: 0;
          transition: opacity 0.2s;
        }
      }

      &:hover {
        .delete-button {
          opacity: 1;
        }
      }
    }
  }
}

@import '../../../../variables.scss';

@mixin list-item {
  display: flex;
  .bullet {
    margin-right: 5px;
  }

  &.active {
    font-weight: 800;
    color: blue !important;
  }

  &.clickable {
    cursor: pointer;

    .main-text {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.not-clickable {
    color: $gray;
    cursor: default;
  }
}

.session-plan-container {

  .session-plan-chapter {
    margin: 5px 5px 5px 5px;

    .session-plan-chapter-text {
      @include list-item;
    }

    .session-plan-chapter-subchapters {
      margin: 5px 5px 5px 20px;

      .session-plan-subchapter-text {
        @include list-item;
      }
    }
  }
}

@import '../../../../variables.scss';

.get-started-examples-container {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .get-started-examples {
    width: 100%;
    display: flex;
    max-width: 800px;
    justify-content: space-around;
    gap: 15px;
  
    @media (max-width: 600px) {
      flex-direction: column;
    }
  
    .get-started-example {
      background-color: $background-gray-ultralight;
      padding: 15px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

@import '../../variables';

.main-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px; /* minimum of 15px between modal and edges of the window */
  height: 100%;
  min-width: calc($small-mobile-breakpoint-min - 2*15px);
}

.main-modal-main {
  background-color: rgba(255, 255, 255);
  max-width: 600px;
  width: 100%;
  padding: 0px;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  
  max-height: calc(100% - 2*60px); /* minimum of 15px between modal and top and bottom of window */
  /* overflow-y: scroll; */
}

.main-modal-nav-bar {
  background-color: white;
  z-index: 1000;
  max-width: 600px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  flex-shrink: 0;
}

.main-modal-nav-bar-inner {
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px 0;
}

.main-modal-nav-bar-exit {
  cursor: pointer;
  padding: 8px;
}

.main-modal-content {
  text-align: center;
  padding: 0 0 0;
  flex-grow: 1;
  overflow: hidden;
}

.main-modal-content .overflow-visible {
  overflow: visible;
}

.main-modal-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
}

.main-modal-buttons-inner {
  padding: 15px;
}

.main-modal-buttons button {
  margin-left: 15px;
}

@media only screen and (max-width: $mobile-breakpoint-max) {
  .main-modal-buttons {
    display: block;
    flex-direction: column;
  }
  .main-modal-buttons button {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 15px 0;
  }
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}
@import '../../../../variables.scss';

.chat-input-container {
  width: 100%;

  .compose-message-body {
    width: 100% !important;
    margin: 15px 0 0 !important;
  }
  
  .error-box-container {
    margin-top: 10px;
  }
}


.selected-image-preview {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.attachment-preview {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.remove-image-button {
  background: none;
  border: none;
  color: red;
  font-size: 18px;
  cursor: pointer;
}

.input-with-attachment {
  position: relative;
  display: flex;
  align-items: flex-start; /* Aligns the button and input */
}

.input-with-attachment-btn {
  z-index: 9999;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 25px;
  font-size: 22px;
}

.attachment-button {
  background: transparent; /* No background for a clean look */
  border: none; /* Remove default button borders */
  cursor: pointer; /* Change cursor to pointer */
  margin-right: 8px; /* Space between button and input */
  font-size: 1.2em; /* Adjust size as needed */
}

/* Optional: Add hover effects */
.attachment-button:hover {
  opacity: 0.8; /* Change opacity on hover */
}


.quick-replies-container {
  padding-top: 6px;

  .quick-reply-button {
    color: $dark-gray;
    background-color: #e4e4e4 !important;
    &:hover {
      background-color: #e4e4e4 !important;
    }
  }
}
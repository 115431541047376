@import "../../variables";

.chat-window-container {
  width: 100%;
  background-color: transparent;
  display: flex;
  // padding: 15px;
  // outline: 2px solid $primary;
  // flex-direction: column;
}

@import '../../../../variables.scss';

$message-padding: 8px;

$messages-spacing-tall: 16px;
$messages-spacing-short: 8px; 
@mixin responsive-margin {
  margin-bottom: $messages-spacing-tall;
  @media (max-height: 600px) {
    margin-bottom: $messages-spacing-short;
  }
}

.chat-message-container {
  display: flex;
  @include responsive-margin;

  &:last-child {
    margin-bottom: 0px;
  }

  &.user {
    justify-content: flex-end;

    .chat-message-inner {
      background-color: $secondary;

      .chat-message-text {
        white-space: pre-wrap;
      }
    }
  }

  &.system {
    justify-content: flex-start;

    .chat-message-inner {
      background-color: $background-gray;
      // Remove overflow-x: scroll unless specifically needed for code blocks
      // background-color: $tertiary;

      .chat-message-text {
        code {
          display: block;
          white-space: pre;
          width: 100%;
          overflow-x: scroll;
          scrollbar-width: none;

          &.inline {
            display: inline;
          }
        }
      }
    }
  }

  .chat-message-inner {
    max-width: 90%;
    padding: $message-padding;
  }
}

.micro-lesson-link {
  display: flex;
  background-color: $background-gray;
  padding: $message-padding;
  border-radius: 4px;
  margin-top: 4px;
  cursor: pointer;
  max-width: 50%;
  @include responsive-margin;

  &:hover {
    background-color: $background-gray-light;
  }

  .micro-lesson-link-main {
    .micro-lesson-link-icon {

    }
    .micro-lesson-link-header {
      font-weight: 700;
    }
  }
  .micro-lesson-link-description {

  }
}
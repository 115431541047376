@import '../../../variables.scss';

.auxiliary-pane {
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-right: $border-thickness solid $background-gray-ultralight;
  margin-right: -$border-thickness;
  height: 100%;
  overflow-y: auto;

  .auxiliary-pane-body {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    .auxiliary-pane-body-contents {
      flex-grow: 1;
      overflow-y: auto;
      padding: 0 20px;
    }
  }
}

@import '../../../variables.scss';

.chat-messages-window {
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  // border: $border-thickness solid $primary;
  height: 100%;

  .chat-window-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    overflow-y: hidden;
    align-items: center;
  }
}

.no-such-chat-session-window {
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;

  .not-found-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 50px;
    align-items: flex-start;
    justify-content: center;

    img {
      width: 100%;
      max-width: 50%;
    }
  }
}
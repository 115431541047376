@import '../../../../variables.scss';

.chat-messages-body {
  flex-grow: 1;
  overflow-y: auto;
  // background-color: $background-gray-ultralight;
  // background-color: pink;
  padding: 10px 10px;

  .chat-messages-body-inner {
    // background-color: orange;
    height: auto;
  }
}

@import '../../../variables.scss';

.audit-chat-session {
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-right: $border-thickness solid $background-gray-ultralight;
  margin-right: -$border-thickness;
  height: 100%;
  overflow-y: auto;

  .audit-chat-session-body {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    .audit-chat-session-body-contents {
      flex-grow: 1;
      overflow-y: auto;
      padding: 0 20px;

      .chat-messages {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .chat-message {
          background-color: $background-gray-ultralight;
          padding: 15px;
          
          .chat-message-queries {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .chat-message-query {
            background-color: $background-gray-light;
            padding: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.query-modal-contents {
  padding: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 15px;

  code {
    display: block;
    white-space: pre-wrap !important;
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
  }

  .query-request {
    background-color: $background-gray-ultralight;
    padding: 15px;
  }

  .query-response {
    background-color: $background-gray-ultralight;
    padding: 15px;
  }
}

$primary: #B5C0D0;
$secondary: #CCD3CA;
$tertiary: #F5E8DD;
$quaternary: #EED3D9;

$dark-gray: #272727;
$gray: #808080;

$background-gray: #dfdfdf;
$background-gray-light: #e9e8e8;
$background-gray-ultralight: #f3efee;

$standard-padding: 15px;
$standard-border-radius: 10px;
$standard-gap: 10px;

// chat
$border-thickness: 2px;

$small-mobile-breakpoint-min: 320px; // smallest supported screen size
$mobile-breakpoint-max: 767px;
$tablet-breakpoint-min: 768px;
